import React, { useEffect, useState } from "react";
import { fetchProfileData, LogoutApi } from "../../shared/api/SignIn";
import { updateProfileData } from "../../shared/api/Profile.jsx";
import { useNavigate } from "react-router-dom";
import * as Styled from "./StyleProfile.jsx";
import logo from "../../img/Logo1.svg";
import AnimatedGradientBackground from "../AnimatedGradient.jsx";

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const editableFields = [
    "username",
    "email",
    "telegram",
    "phone_number",
    "career_goals",
    "social_media",
  ];

  useEffect(() => {
    fetchProfileData()
      .then((data) => {
        setProfileData(data);
        setFormData(data);
      })
      .catch(() => setError("Ошибка при загрузке данных профиля"));
  }, []);

  const handleLogout = async () => {
    try {
      await LogoutApi();
      navigate("/login");
    } catch (error) {
      setError("Ошибка при выходе из аккаунта");
    }
  };

  const handleEdit = (field) => {
    setEditingField(field);
  };

  const handleSave = async () => {
    try {
      await updateProfileData(formData);
      setEditingField(null);
      setProfileData(formData);
    } catch {
      setError("Ошибка при сохранении изменений");
    }
  };

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Styled.Profile>
      <AnimatedGradientBackground />
      <Styled.ReturnButtonContainer>
        <Styled.ReturnButton onClick={() => (window.location.href = "/")}>
          На главную
        </Styled.ReturnButton>
      </Styled.ReturnButtonContainer>

      <Styled.ProfileContainer>
        <Styled.LogoContainer>
          <Styled.Logo
            src={logo}
            alt="Логотип"
            onClick={() => (window.location.href = "/")}
          />
        </Styled.LogoContainer>

        <Styled.Title>Профиль пользователя</Styled.Title>
        {error ? (
          <Styled.ErrorText>{error}</Styled.ErrorText>
        ) : (
          profileData && (
            <Styled.ProfileInfo>
              {Object.keys(profileData).map((field) => {
                return (
                  <Styled.ProfileFieldContainer key={field}>
                    <strong>{field}: </strong>
                    {editingField === field ? (
                      <Styled.EditableInput
                        isEditing
                        value={formData[field] || ""}
                        onChange={(e) => handleChange(field, e.target.value)}
                      />
                    ) : (
                      <span>{profileData[field] || "Не указан"}</span>
                    )}
                    {editableFields.includes(field) && (
                      <Styled.EditButton
                        onClick={() => handleEdit(field)}
                        isLessVisible
                      >
                        Изменить
                      </Styled.EditButton>
                    )}
                  </Styled.ProfileFieldContainer>
                );
              })}
            </Styled.ProfileInfo>
          )
        )}
        {editingField && (
          <Styled.SaveButton onClick={handleSave}>Сохранить</Styled.SaveButton>
        )}
        <Styled.LogoutButton onClick={handleLogout}>Выйти</Styled.LogoutButton>
      </Styled.ProfileContainer>

      <Styled.SessionButtonContainer>
        <Styled.SessionButton
          onClick={() => (window.location.href = "/sessions")}
        >
          Сессии
        </Styled.SessionButton>
      </Styled.SessionButtonContainer>
    </Styled.Profile>
  );
};

export default Profile;
