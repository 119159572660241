const BASE_URL = "https://core.andreis-vibes.ru";

const updateProfileData = async (updatedData) => {
  const token = localStorage.getItem("authToken");
  if (!token) throw new Error("Токен отсутствует, выполните вход");

  try {
    const response = await fetch(`${BASE_URL}/account/profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw new Error("Ошибка при обновлении профиля");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при обновлении профиля:", error);
    throw error;
  }
};

export { updateProfileData };
