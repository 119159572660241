const BASE_URL = "https://core.andreis-vibes.ru";

const LoginApi = async (username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/account/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error("Ошибка при входе");
    }

    const data = await response.json();

    const token = data.token;
    if (token) {
      localStorage.setItem("authToken", token);
      console.log("Токен сохранен в локальном хранилище");

      return { success: true };
    } else {
      throw new Error("Токен не был получен");
    }

  } catch (error) {
    console.error("Ошибка при входе:", error);
    return { success: false, errorMsg: error.message };
  }
};

const fetchProfileData = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/account/profile`, { 
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении данных профиля");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при загрузке данных профиля:", error);
    throw error;
  }
};

const LogoutApi = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/account/logout`, { 
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при выходе из аккаунта");
    }

    localStorage.removeItem("authToken");
    return { success: true };

  } catch (error) {
    console.error("Ошибка при выходе из аккаунта:", error);
    throw error;
  }
};

const ForgotPasswordApi = async (data) => {
  try {
    const response = await fetch(`${BASE_URL}/account/forgot_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Ошибка при восстановлении пароля");
    }

    return { success: true };
  } catch (error) {
    console.error("Ошибка при восстановлении пароля:", error);
    return { success: false, errorMsg: error.message };
  }
};


export { LoginApi, fetchProfileData, LogoutApi, ForgotPasswordApi };
