import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "./StyleSignIn.jsx";
import { LoginApi } from "../../shared/api/SignIn.jsx";
import AnimatedGradientBackground from "../AnimatedGradient.jsx";
import ForgotPasswordModal from "./ForgotPasswordModal.jsx";
import CustomInput from "../CommonComponents/Input.jsx"; 
import logo from "../../img/Logo1.svg";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { success, errorMsg } = await LoginApi(username, password);
    if (success) {
      setError("");
      navigate("/profile");
    } else {
      setError(errorMsg || "Ошибка при входе");
    }
  };

  return (
    <Styled.Login>
      <AnimatedGradientBackground />
      <Styled.ReturnButtonContainer>
        <Styled.ReturnButton onClick={() => (window.location.href = "/")}>
          На главную
        </Styled.ReturnButton>
      </Styled.ReturnButtonContainer>
      <Styled.VerticalStripe />

      <Styled.Logo src={logo} alt="Логотип" />

      <Styled.LoginContainer>
        <Styled.Title>Авторизация</Styled.Title>

        <Styled.Form onSubmit={handleSubmit}>
          <CustomInput
            type="text"
            placeholder="Логин"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <CustomInput
            type="password"
            placeholder="Ваш пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Styled.ButtonContainer>
            <Styled.ButtonMain type="submit">Войти</Styled.ButtonMain>
            {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
            <Styled.ForgotPasswordButton
              type="button"
              onClick={handleForgotPassword}
            >
              Забыли пароль?
            </Styled.ForgotPasswordButton>
          </Styled.ButtonContainer>
        </Styled.Form>

        <ForgotPasswordModal
          isOpen={forgotPassword}
          onClose={() => setForgotPassword(false)}
        />
      </Styled.LoginContainer>
    </Styled.Login>
  );
};

export default Login;
