import './styles/App.css'
import React from 'react';
import MainPage from './components/MainPage/MainPage.jsx';
import Login from './components/Login/Login.jsx';
import Loyalty from './components/Loyalty/Loyalty.jsx';
import Booking from './components/Booking/Booking.jsx';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NotFound } from './NotFound.jsx';
import Profile from './components/Profile/Profile.jsx';
import Session from './components/Profile/Sessions.jsx';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/loyalty" element={<Loyalty />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/sessions" element={<Session />} />
      <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
