import styled from "styled-components";
import { spacing, sizes } from '../CommonComponents/Sizes.jsx';

export const Login = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 660px) {
    width: 95%;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 3.69rem;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: ${spacing.xlarge};
  z-index: 2;
  width: 20rem;

  @media (max-width: 1024px) {
    top: ${spacing.medium};
    width: 16rem;
  }

  @media (max-width: 660px) {
    top: ${spacing.small};
    width: 12rem;
  }
`;

export const Title = styled.div`
  font-size: 48px;
  margin-bottom: ${spacing.large};

  @media (max-width: 1024px) {
    font-size: 32px;
    margin-bottom: ${spacing.medium};
  }

  @media (max-width: 660px) {
    font-size: 24px;
    margin-bottom: ${spacing.small};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing.medium};
  width: 26.38rem;

  @media (max-width: 1024px) {
    width: 20rem;
  }

  @media (max-width: 660px) {
    width: 16rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: ${sizes.inputHeight};
  border-bottom: 3px solid rgba(149, 224, 178, 1);
  border-radius: 0.25rem;
  background-color: rgba(16, 24, 32, 1);
  padding: 0.69rem 0 0.69rem 1.5rem;
  font-size: 32px;

  &::placeholder {
    text-align: left;
    color: rgba(140, 140, 140, 1);
    font-size: 32px;
  }

  @media (max-width: 1024px) {
    height: ${sizes.inputHeightTablet};
    font-size: 24px;

    &::placeholder {
      font-size: 24px;
    }
  }

  @media (max-width: 660px) {
    height: 40px;
    font-size: 20px;

    &::placeholder {
      font-size: 20px;
    }
  }
`;

export const ButtonMain = styled.button`
  width: 400px;
  height: ${sizes.buttonHeight};
  background-color: #95e0b2;
  color: black;
  padding: 11px;
  font-size: 32px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, font-size 0.3s ease;

  &:hover {
    background-color: #73ae8a;
  }

  &:active {
    background-color: rgba(40, 30, 113, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(2px);
    color: white;
    animation: buttonActive 300ms ease-in-out forwards;
  }

  @media (max-width: 1024px) {
    width: 300px;
    height: ${sizes.buttonHeightTablet};
    font-size: 24px;
  }

  @media (max-width: 660px) {
    width: 240px; 
    height: 50px; 
    font-size: 20px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 660px) {
    font-size: 16px;
  }
`;

export const VerticalStripe = styled.div`
  position: absolute;
  left: 50%;
  width: 37.5rem;
  height: 100%;
  background-color: rgba(40, 30, 113, 1);
  opacity: 0.5;
  transform: translateX(-50%);
  mix-blend-mode: multiply;

  @media (max-width: 1024px) {
    width: 30rem;
  }

  @media (max-width: 660px) {
    width: 20rem;
  }
`;

export const ForgotPasswordButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.25rem;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }

  @media (max-width: 660px) {
    font-size: 0.875rem;
  }
`;


export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.small};
`;

export const ReturnButtonContainer = styled.div`
  position: absolute;
  top: ${spacing.small};
  left: ${spacing.small};
  z-index: 2;
`;

export const ReturnButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 32px;
`;
