import styled from "styled-components";
import { spacing, sizes } from '../CommonComponents/Sizes.jsx';

export const MainPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const HomePage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const Logo = styled.img`
  position: absolute;
  top: 3.69rem; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @media (max-width: 1024px) {
    top: ${spacing.medium};
    width: 18rem;
  }

  @media (max-width: 660px) {
    top: ${spacing.small};
    width: 14rem; 
  }
`;

export const Headline = styled.div`
  font-size: 48px;
  margin-bottom: ${spacing.large};

  @media (max-width: 1024px) {
    font-size: 32px;
    margin-bottom: ${spacing.medium};
  }

  @media (max-width: 660px) {
    font-size: 24px;
    margin-bottom: ${spacing.small};
  }
`;

export const LinkList = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${spacing.medium};

  @media (max-width: 1024px) {
    gap: ${spacing.small};
  }

  @media (max-width: 660px) {
    gap: ${spacing.xsmall};
  }
`;

export const ButtonMain = styled.button`
  width: 400px;
  height: ${sizes.buttonHeight};
  background-color: #95e0b2;
  color: black;
  padding: 11px;
  font-size: 32px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, font-size 0.3s ease;

  &:hover {
    background-color: #73ae8a;
  }

  &:active {
    background-color: rgba(40, 30, 113, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(2px);
    color: white;
    animation: buttonActive 300ms ease-in-out forwards;
  }

  @media (max-width: 1024px) {
    width: 300px;
    height: ${sizes.buttonHeightTablet};
    font-size: 24px;
  }

  @media (max-width: 660px) {
    width: 240px; 
    height: 50px; 
    font-size: 20px;
  }
`;

export const VerticalStripe = styled.div`
  position: absolute;
  left: 50%;
  width: 37.5rem; 
  height: 100%;
  background-color: rgba(40, 30, 113, 1);
  opacity: 0.5;
  transform: translateX(-50%);
  mix-blend-mode: multiply;

  @media (max-width: 1024px) {
    width: 30rem;
  }

  @media (max-width: 660px) {
    width: 20rem; 
  }
`;
