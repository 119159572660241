import styled from "styled-components";

export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  flex-direction: column;
`;

export const ProfileContainer = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 20px;
  background: rgb(16, 24, 32);
  background: linear-gradient(
    90deg,
    rgba(16, 24, 32, 1) 50%,
    rgba(40, 30, 113, 1) 120%
  );
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 2;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  z-index: 2;
`;

export const Logo = styled.img`
  width: 200px;
  cursor: pointer;
`;

export const Title = styled.h1`
  text-align: center;
  color: white;
  font-size: 24px;
`;

export const ProfileInfo = styled.div`
  margin-top: 20px;
`;

export const ProfileField = styled.p`
  margin: 10px 0;
  font-size: 16px;
  color: white;
  opacity: 80%;

  strong {
    color: white;
  }

  img {
    max-width: 100px;
    height: auto;
    border-radius: 8px;
  }
`;

export const ErrorText = styled.p`
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
`;

export const LogoutButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  color: red;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

export const ReturnButtonContainer = styled.div`
  position: absolute; 
  margin: 10px 10px;
  top: 20px; 
  left: 20px;
  z-index: 2;
`;

export const ReturnButton = styled.button`
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const SessionButtonContainer = styled.div`
  position: absolute; 
  margin: 10px 10px;
  top: 20px; 
  right: 20px;
  z-index: 2;
`;

export const SessionButton = styled.button`
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const SaveButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const EditButton = styled.button`
  background: none;
  border: none;
  color: #888;
  font-size: 0.9em;
  cursor: pointer;
  padding: 4px;
  
  &:hover {
    color: #555;
  }
`;

export const ProfileFieldContainer = styled.div`
  margin-bottom: 15px;
`;

export const EditableInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  transition: border-color 0.3s ease;

`;