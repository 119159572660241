// CommonComponents/Sizes.jsx
export const spacing = {
  large: "3.75rem",   // 60px
  medium: "2.5rem",   // 40px
  small: "1rem",      // 16px
  xlarge: "11.87rem", // 189.92px
  xsmall: "0.75rem",  // 12px
  xxsmall: "0.5rem",  // 8px
};

export const sizes = {
  inputHeight: "3.75rem",        // 60px
  buttonHeight: "3.75rem",       // 60px
  buttonWidth: "9.63rem",        // 154px
  buttonHeightTablet: "3rem",    // 48px
  buttonWidthTablet: "8rem",      // 128px
  buttonHeightMobile: "2.5rem",  // 40px
  buttonWidthMobile: "6rem",      // 96px
};
