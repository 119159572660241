const BASE_URL = "https://core.andreis-vibes.ru";

const getCurrentSession = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении текущего сеанса");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при получении текущего сеанса:", error);
    throw error;
  }
};

const getAllSessions = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении всех сеансов");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при получении всех сеансов:", error);
    throw error;
  }
};

const checkTokenStatus = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/check`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при проверке статуса токена");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при проверке статуса токена:", error);
    throw error;
  }
};

const deleteAllSessions = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/all`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Ошибка при удалении всех сеансов: ${errorData.message}`);
    }

    return { success: true, message: "Все сеансы успешно удалены" };

  } catch (error) {
    console.error("Ошибка при удалении всех сеансов:", error);
    throw error;
  }
};

const getSessionStats = async (sessionId) => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/${sessionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении статистики по сеансу");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при загрузке статистики по сеансу:", error);
    throw error;
  }
};

const deleteSession = async (sessionId) => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/${sessionId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при удалении сессии");
    }

    return { success: true };

  } catch (error) {
    console.error("Ошибка при удалении сессии:", error);
    throw error;
  }
};

export { getCurrentSession, getAllSessions, checkTokenStatus, deleteAllSessions, getSessionStats, deleteSession };
